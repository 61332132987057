<template>
  <div>
    <div class="page-wrapper chiller-theme" :class="{ toggled: isActive }">
      <main class="col-sm-10 col-md-10 col-lg-10 col-xs-10" 
      :style="isActive ? 'float: right;' : 'margin-left: 152px;'">
        <div class="">
          <p-connector-list
            v-show="connectorsList"
            @handalConnectors="selectedConnectors($event)"
          ></p-connector-list>
          <p-connector-summary
            v-show="connectorsSummary"
            @back="back($event)"
            :orderedListOfConnectors="orderedListOfConnectors"
          ></p-connector-summary>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import ConnectorsList from "./ChannelsList.vue";
import ConnectorSummary from "./SelectedChannels.vue";

export default {
  props: [],
  components: {
    "p-connector-list": ConnectorsList,
    "p-connector-summary": ConnectorSummary,
  },
  data() {
    return {
      isActive: false,
      connectorsSummary: false,
      connectorsList: true,
      padding: 0,
      menuMargin: 70,
      orderedListOfConnectors: [],
      username:''
    };
  },
  methods: {
    back(event) {
      this.connectorsSummary = false;
      this.connectorsList = true;
      console.log(event);
    },
    selectedConnectors(event) {
      var digitalMedia = [];
      var anlytics = [];
      
      this.orderedListOfConnectors = [];
      for (var i = 0; i < event.length; i++) {
        if (event[i].title == "Digital Media") {
          digitalMedia.push({
            name: event[i].connectorName,
            className: event[i].className,
            icon: event[i].icon
          });
        } else if (
          event[i].title == "Analytics" ||
          event[i].title == "App Analytics"
        ) {
          anlytics.push({
            name: event[i].connectorName,
            className: event[i].className,
            icon: event[i].icon
          });
        } 
        if (event.length - 1 == i) {
          this.orderedListOfConnectors.push(
            {
              title: "Digital Media",
              connectors: digitalMedia,
            },
            {
              title: "Analytics",
              connectors: anlytics,
            },
            
          );
        }
      }
      this.connectorsSummary = true;
      this.connectorsList = false;
    },
  },
  created() {
    this.username = sessionStorage.getItem("userName");
  },
};
</script>
<style scoped></style>
