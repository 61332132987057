<template>
  <div>
    <div class="page-wrapper pt-4">
      <div class="btn-back" @click="back">
        <img src="@/assets/Icons/arrow-left-line.svg" alt="back" height="35" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="">
          <div class="card">
            <div class="card-body">
              <div class="card-header" style="border-bottom: 2px solid #fff">
                <h4 class="title">Summary of Selected Channels</h4>
              </div>
              <div class="card-body">
                <div class="mediaIcons" style="padding: 15px">
                  <div
                    class="row"
                    v-for="(orderedListOfConnector,i) in orderedListOfConnectors"
                    :key="i"
                    :class="{
                      disable: orderedListOfConnector.connectors.length == 0,
                    }"
                  >
                    <h6 class="title">{{ orderedListOfConnector.title }}</h6>
                    <div
                      class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                      v-for="(connector,i) in orderedListOfConnector.connectors"
                      :key="i"
                    >
                      <div class="iconBox active">
                        <img class="icon" v-bind:src="connector.icon" />

                        <span
                          class="iconArea"
                          :class="connector.className"
                        ></span>
                        <span class="iconText">{{ connector.name }}</span>
                        <span class="iconSelected">
                          <i class="fa fa-check-circle" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <center>
                  <button class="submit-button" @click="gotoLearning">
                    Next
                  </button>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["orderedListOfConnectors"],
  data: function () {
    return {};
  },
  methods: {
    back() {
      this.$emit("back", true);
    },
    gotoLearning() {
      this.$router.push("/digital-planning/performance/dashboard");
    },
  },
};
</script>>
<style scoped>
.title {
  display: block;
  width: 100%;
  margin: 20px 10px 12px;
  font-size: 18px;
}
.mediaIcons .iconBox:not(.disabled) {
  background-color: #e9f0ff;
  padding: 3rem;
  align-self: center;
}
.mediaIcons .iconBox {
  width: 100%;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 10px;
  display: flex;
  height: 45px;
  padding: 5px;
  margin: 5px 0;
  cursor: pointer;
}
.mediaIcons .iconBox .iconArea {
  width: 30px;
  height: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 90%;
}
.mediaIcons .iconBox .iconText {
  padding: 4px 10px;
  width: 75%;
  align-self: center;
  font-size: 14px;
}
.mediaIcons .iconBox.active .iconSelected,
.mediaIcons .iconBox.connected .iconSelected,
.mediaIcons
  .iconBox:not(.disabled):hover
  .mediaIcons
  .iconBox.connected
  .iconSelected {
  color: #4aef49;
  align-self: center;
}
.mediaIcons .iconBox .iconSelected {
  font-size: 1.2rem;
}
.mediaIcons .iconBox.active .iconSelected,
.mediaIcons .iconBox.connected .iconSelected,
.mediaIcons .iconBox:not(.disabled):hover .iconSelected {
  display: inline-block;
}
.iconText {
  font-size: 14px;
  font-weight: 700;
}
.card {
  box-shadow: none;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);

}
.card-header h4 {
  color: #000;
  margin: 20px 0;
  font-size: 22px;
  font-weight: 600;
}
.card-header {
  border-bottom: 0 none;
  background: none;
}
.mediaIcons .iconBox.active {
  background: #e9f0ff;
  padding: 3rem;
  color: #1c2635;
  display: flex;
}
.row.disable {
  display: none;
}

.next-btn {
  display: grid;
  place-items: center;
}
.next-btn span {
  color: #fff;
  background: #d92e72;
  border-radius: 12px;
  padding: 2px 20px;
  cursor: pointer;
  font-size: 14px;
}

.heading {
  font-size: 22px;
  color: #000;
}

.submit-button {
  margin-top: 40px;
  background: none;
  border-radius: 5px;
  color: #fff;
  background: #000;
  display: block;
  align-items: center;
  font-weight: bold;
  border-radius: 8;
  border-width: 0;
  width: 200px;
  height: 50px;
  font-size: 18px;
}
.submit-button:hover {
  background: #111114;
}
.icon {
  align-self: center;
  width: 30px;
}
.page-wrapper{
margin-top: 90px;
}
</style>