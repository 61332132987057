<template>
  <div>
    <div class="page-wrapper pt-4">
      <div class="btn-back" @click="backToDigitalPlan">
        <img src="@/assets/Icons/arrow-left-line.svg" alt="back" height="35" />
      </div>
    </div>
    <div class="row mb-5 mt-5">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="">
          <div class="card">
            <div class="card-body">
              <div class="row tabList">
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                  style="cursor: pointer"
                  v-for="tab in tabs"
                  :key="tab"
                  @click="showTabContent(tab.name)"
                >
                  <span
                    class="nav-link"
                    :class="{ active: selectedTab == tab.name }"
                  >
                    <span>
                      <i class="fas" :class="tab.class"></i>
                    </span>
                    <span>{{ tab.name }}</span>
                  </span>
                </div>
              </div>
              <div class="tab-content mediaIcons">
                <div
                  class="tab-pane"
                  v-for="tab in tabs"
                  :key="tab"
                  :class="{ active: selectedTab === tab.name }"
                >
                  <div
                    class="row"
                    v-for="tabContent in tab.tabContents"
                    :key="tabContent"
                  >
                    <h5>{{ tabContent.title }}</h5>
                    <div
                      class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                      v-for="connector in tabContent.connectors"
                      :key="connector"
                      @click="
                        selectConnector(
                          connector.name,
                          tabContent.title,
                          connector.class,
                          connector.role,
                          connector.icon
                        )
                      "
                    >
                      <div
                        class="iconBox"
                        :class="{
                          active:
                            selectedConnectors.find(
                              (connectorObj) =>
                                connectorObj.role === connector.role
                            ) != undefined,
                        }"
                      >
                        <img class="icon" v-bind:src="connector.icon" />
                        <span class="iconArea" :class="connector.class"></span>
                        <span class="iconText">{{ connector.name }}</span>
                        <span class="iconSelected">
                          <i class="fa fa-check-circle" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                    <div
                      class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                      style="padding-top: 15px"
                      v-if="tab.upload"
                    >
                      <span
                        ><i
                          class="fa fa-upload uploadDownloadBtn"
                          aria-hidden="true"
                        ></i
                      ></span>
                      <span
                        ><i
                          class="fa fa-download uploadDownloadBtn"
                          aria-hidden="true"
                        ></i
                      ></span>
                    </div>
                  </div>
                </div>
                <div style="text-align: center">
                  <center>
                    <button
                      class="submit-button"
                      :disabled="isDisable"
                      :style="
                        isDisable ? 'cursor: not-allowed;' : 'cursor: pointer;'
                      "
                      :class="{ disableBtn: isDisable }"
                      @click="selectedConnectorsData"
                    >
                      Next
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      disable: true,
      tabs: [
        {
          name: "Digital Media",
          class: "fa-newspaper",
          upload: false,
          tabContents: [
            {
              title: "Digital Media",
              connectors: [
                {
                  name: "Google Ads",
                  class: "googleAd",
                  role: "googleAd",
                  icon: require("@/assets/connectors/google-ad.png"),
                },
                {
                  name: "Facebook",
                  class: "facebook",
                  role: "facebook",
                  icon: require("@/assets/connectors/Facebook.png"),
                },
                {
                  name: "Instagram",
                  class: "instagram",
                  role: "instagram",
                  icon: require("@/assets/connectors/instagram.svg"),
                },
                {
                  name: "Twitter",
                  class: "twitter",
                  role: "twitter",
                  icon: require("@/assets/connectors/twitter.png"),
                },
                {
                  name: "LinkedIn",
                  class: "linkedin",
                  role: "linkedin",
                  icon: require("@/assets/connectors/LinkedIn.png"),
                },
                {
                  name: "Programmatic",
                  class: "program",
                  role: "program",
                  icon: require("@/assets/connectors/program.png"),
                },
                {
                  name: "Publishers",
                  class: "appFlyer",
                  role: "publisher",
                  icon: require("@/assets/connectors/appsFlyer.jpg"),
                },
              ],
            },
            {
              title: "Analytics",
              connectors: [
                {
                  name: "Google Analytics",
                  class: "googleAnal",
                  role: "googleAnal",
                  icon: require("@/assets/connectors/google-anal.png"),
                },
                {
                  name: "Adobe Analytics",
                  class: "adobeAnal",
                  role: "adobeAnal",
                  icon: require("@/assets/connectors/Adobe Analytics.png"),
                },
              ],
            },
            {
              title: "App Analytics",
              connectors: [
                {
                  name: "App Flyer",
                  class: "appFlyer",
                  role: "appFlyer",
                  icon: require("@/assets/connectors/appsFlyer.jpg"),
                },
              ],
            },
          ],
        },
      ],
      selectedTab: "Digital Media",
      selectedConnectors: [],
    };
  },
  methods: {
    showTabContent(name) {
      this.selectedTab = name;
    },
    backToDigitalPlan() {
      this.$router.push("/digital-planning");
    },
    selectConnector(connector, title, className, role, icon) {
      if (
        this.selectedConnectors.find((connector) => connector.role === role) !=
        undefined
      ) {
        for (var i = 0; i < this.selectedConnectors.length; i++) {
          if (this.selectedConnectors[i].role == role) {
            this.selectedConnectors.splice(i, 1);
          }
        }
        console.log(title, connector);
      } else {
        this.selectedConnectors.push({
          title: title,
          connectorName: connector,
          className: className,
          role: role,
          icon: icon,
        });
      }
    },
    selectedConnectorsData() {
      this.$emit("handalConnectors", this.selectedConnectors);
    },
  },
  computed: {
    isDisable() {
      if (this.selectedConnectors.length != 0) {
        return false;
      }
      return this.disable;
    },
  },
};
</script>
>
<style scoped>
.mediaIcons .iconBox .iconArea {
  width: 30px;
  height: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 90%;
}
.mediaIcons .iconBox .iconText {
  padding: 4px 10px;
  width: 75%;
  align-self: center;
  font-size: 14px;
}
.mediaIcons .iconBox.active .iconSelected,
.mediaIcons .iconBox.connected .iconSelected,
.mediaIcons .iconBox:not(.disabled):hover .iconSelected {
  display: inline-block;
}
.mediaIcons .iconBox .iconSelected {
  font-size: 1.2rem;
  color: #d92e72;
  display: none;
}
.mediaIcons .iconBox.active {
  background: #e9f0ff;
  color: #1c2635;
  padding: 3rem;
  display: flex;
}
.mediaIcons .iconBox .iconSelected {
  font-size: 1.2rem;
  color: #1c2635;
  display: none;
}
.mediaIcons .iconBox .iconSelected {
  font-size: 1.2rem;
  color: #d92e72;
  display: none;
  align-self: center;
}
.mediaIcons .iconBox:not(.disabled) {
  background-color: #e9f0ff;
  padding: 3rem;
  align-self: center;
}

.mediaIcons .iconBox {
  width: 100%;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 10px;
  display: flex;
  height: 45px;
  padding: 5px;
  margin: 5px 0;
  cursor: pointer;
}
.tab-content .tab-pane {
  padding: 1rem;
}
.mediaIcons .iconBox.active .iconSelected,
.mediaIcons .iconBox.connected .iconSelected,
.mediaIcons
  .iconBox:not(.disabled):hover
  .mediaIcons
  .iconBox.connected
  .iconSelected {
  color: #4aef49;
  align-self: center;
}
.mediaIcons h5 {
  display: block;
  width: 100%;
  margin: 20px 10px 12px;
}

.tabList {
  border-bottom: 1px solid #ccc;
}
.card {
  box-shadow: none;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
}
.tabList .nav-link.active,
.tabList .nav-link:hover {
  border-bottom: 3px solid #000;
}
.tabList .nav-link {
  color: inherit;
  font-size: 1rem;
}
.nav-link {
  display: block;
  padding-bottom: 0.5rem;
}
.tabList .nav-link span:first-child {
  font-size: 2rem;
  color: #d92e72;
}
.tabList .nav-link span {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .tab-pane.active {
  display: block;
}
.uploadDownloadBtn {
  padding: 10px 20px;
  background-color: gray;
  border-radius: 15px;
}
.submit-button {
  margin-top: 40px;
  background: none;
  border-radius: 5px;
  color: #fff;
  background: #000;
  display: block;
  align-items: center;
  font-weight: bold;
  border-radius: 8;
  border-width: 0;
  width: 200px;
  height: 50px;
  font-size: 18px;
}
.submit-button:hover {
  background: #111114;
}

.icon {
  align-self: center;
  width: 30px;
}
.page-wrapper {
  margin-top: 90px;
}
</style>
